import React from 'react';
import ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals';
import "./index.css"
import {
  RouterProvider,
} from "react-router-dom";
import { PageRoutes } from 'Routes/Routes';
ReactDOM.render(
  <React.StrictMode>
    <PageRoutes />
  </React.StrictMode>,
  document.getElementById('root')
)


reportWebVitals()
