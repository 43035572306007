import "./RecruiterWelcome.css"
import React, { Children } from 'react';
import { redirect,useNavigate } from 'react-router-dom';
import PortalSideBar from 'Common/PortalSideBar/PortalSideBar';
import SearchBar from 'Common/SearchBar/SearchBar';
import CenterImg from '../../Assets/center_img.svg';
import BannerImg from '../../Assets/banner.png';
import AiImg from '../../Assets/aiBanner.svg';
import Close from '../../Assets/close.png';

function RecruiterWelcome() {
  const nav = useNavigate()
  const [userName,setUserName] = React.useState<string>('')
  React.useEffect(()=>{
    const userData = window.localStorage.getItem('user');
   
      if(userData){
        const parsedData = JSON.parse(userData);
      
        setUserName(parsedData?.user?.name)
        
      }
     },[])
  return (
   
      <div className="main">
        <div className="welcomeBanner">
        <img src={AiImg} alt="" className="ai_img" />
            <div className="Welcome_text">Hello {userName}, Welcome to R2O</div>
            <div className="right_telent">Let’s find the right talent by posting few job openings</div>
            <div className="post_new_opning_btn">Post New Opening</div>
            <div className="add_from_job_portal">Add from Job Portals</div>
            <img src={Close} alt="" className="close_img" />
        </div>
    
      <div className="center_image" >
            <img src={CenterImg} className='' />
      </div>
      </div>
     
  );
}

export default RecruiterWelcome;