import './PortalSideBar.css';
import React, { Children, FC } from 'react';
import { Button, Grid, TextField, Tooltip, Typography } from '@mui/material';
import Logo from '../../Assets/LogoClr.svg'
import DashboardIcon from '../../Assets/dashboard.png'
import DashboardActiveIcon from '../../Assets/dashboard_active.png'
import BusinessIconActive from '../../Assets/business_center_active.png'
import BusinessIcon from '../../Assets/business_center.png'
import ProfileIcon from '../../Assets/profileIcon.png'
import ProfileIconActive from '../../Assets/profile_active.png'
import SettingIcon from '../../Assets/settings.png'
import SettingIconActive from '../../Assets/settings_active.png'
import AiLogo from '../../Assets/ai.png'
import { useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
interface PortalSideBarProps {
  activeTab:string
  changeTab?:(value:string)=>void
}
const PortalSideBar: FC<PortalSideBarProps> = ({activeTab,changeTab}) => {
  const nav = useNavigate()
  const [userName,setUserName] = React.useState<string>('')

const handleClickProfile = React.useCallback(()=>{
  const userData = window.localStorage.getItem('user')
  const role = userData?JSON.parse(userData)?.user?.roleId:''
  
  if(role===2){
    nav('/recruiterProfile')
  }
  else {
    nav('/userProfile')

  }
},[])
 const Logout= ()=>{
  window.localStorage.clear()
  nav('/signin')
 }
React.useEffect(()=>{
  const userData = window.localStorage.getItem('user');
 
    if(userData){
      const parsedData = JSON.parse(userData);
    
      setUserName(parsedData?.user?.name)
      
    }
   },[])

  return (
  <div className='portalSideBar'>
  <div><img className='Sidebarlogo' src={Logo} alt="logo" /></div>
  <div><img className='dashboardIcon' src={DashboardIcon} alt="icon" /></div>
  <div  onClick={()=>changeTab?.('Amazone')}><img className='businessIcon' src={activeTab==='Amazone' || activeTab==='welcome' ?BusinessIconActive: BusinessIcon} alt="icon" /></div>
  <div><img className='profileIcon' src={ProfileIcon} alt="icon" /></div>
  <div onClick={()=>changeTab?.('settings')}><img className='settingeIcon' src={activeTab==='settings'?SettingIconActive: SettingIcon} alt="icon" /></div>
    <img className='aiIcon' src={AiLogo} alt="icon" />
    <div className='seprationLine'></div>
    <div className='profileInitalBlock'><div onClick={handleClickProfile}  className='profileInital'>{userName[0]}</div></div>
    <div className='profileInitalBlock'><Tooltip  title="Log Out"><LogoutIcon onClick={()=>Logout()} htmlColor='#2468cc' /></Tooltip></div>
  </div>
  );
}

export default PortalSideBar;