

import React, { Children,FC } from 'react';
import { Button, Grid, TextField, Typography } from '@mui/material';
import Logo from '../../Assets/LogoClr.svg'
import './sidebarRecruiterProfile.css'
interface SidebarRecruiterProfileProps {
  currentSidebarPage: string
  innerPageCount:number

}
const SidebarRecruiterProfile: FC<SidebarRecruiterProfileProps> = ({ currentSidebarPage,innerPageCount}) => {
 
  const renderTabs = React.useCallback((count:number,type:string)=>{
 
    return <div className='tabsWrapper'>
      <div className='lineStudentProfile'  style={{backgroundColor:currentSidebarPage===type?'#0250C5':'#D8DBE1'}}></div>

    {(count===2 || count===3) &&<div  style={{backgroundColor:currentSidebarPage===type && (innerPageCount===2 || innerPageCount===3 ) ?'#0250C5':'#D8DBE1'}} 
    className='lineStudentProfile'></div>}
    {(count===3) &&<div  style={{backgroundColor:currentSidebarPage===type && (innerPageCount===3) ?'#0250C5':'#D8DBE1'}} 
    className='lineStudentProfile'></div>}
    
    </div>
  },[currentSidebarPage,innerPageCount])
  return (
    <div className="sidebarWrapperRecruiterProfile">
        <img className='sidebarWrapperStudentProfileLogo' src={Logo} alt="logo" />
  <div className='sidebarContentProfile'>
       <div className='categoryWrapper' ><span className='categoryText' >Account</span> {renderTabs(1,'RecruiterAccount')} </div>
       <div className='categoryWrapper' ><span className='categoryText'>Company</span>{renderTabs(3,'Company')}</div>
       <div className='categoryWrapper'><span className='categoryText'>Terms & Condition</span>{renderTabs(1,'Terms')}</div>
       

  </div>
       </div>
  );
}

export default SidebarRecruiterProfile;