

import React, { Children,FC } from 'react';
import { Button, Grid, TextField, Typography } from '@mui/material';
import Logo from '../../Assets/LogoClr.svg'
import './sidebarStudentProfile.css'
interface SidebarStudentProfileProps {
  currentSidebarPage: string
  innerPageCount:number

}
const SidebarStudentProfile: FC<SidebarStudentProfileProps> = ({ currentSidebarPage,innerPageCount}) => {
 
  const renderTabs = React.useCallback((count:number,type:string)=>{
    
    return <div className='tabsWrapper'><div className='lineStudentProfile'  style={{backgroundColor:currentSidebarPage===type?'#0250C5':'#D8DBE1'}}></div>
    {count===2 &&<div  style={{backgroundColor:currentSidebarPage===type && innerPageCount===2?'#0250C5':'#D8DBE1'}} 
    className='lineStudentProfile'></div>}</div>
  },[currentSidebarPage,innerPageCount])
  return (
    <div className="sidebarWrapperStudentProfile">
        <img className='sidebarWrapperStudentProfileLogo' src={Logo} alt="logo" />
  <div className='sidebarContentProfile'>
       <div className='categoryWrapper' ><span className='categoryText' >Career Track</span> {renderTabs(2,'Career')} </div>
       <div className='categoryWrapper' ><span className='categoryText'>Account</span>{renderTabs(2,'Account')}</div>
       <div className='categoryWrapper'><span className='categoryText'>Education</span>{renderTabs(1,'Education')}</div>
       <div className='categoryWrapper' ><span className='categoryText'>Experiences       </span>{renderTabs(1,'Experiences')}</div>
       <div className='categoryWrapper'><span className='categoryText'>Skills</span>{renderTabs(1,'Skills')}</div>
       <div className='categoryWrapper'><span className='categoryText'>Preferences</span>{renderTabs(2,'Preferences')}</div>
       <div className='categoryWrapper'><span className='categoryText'>Others</span>{renderTabs(1,'Others')}</div>
       {/* <div className='profileStrengthWrapper'><div className='profileStrengthBadge'>90%</div><span className='profileStrengthText'>Profile Strength</span></div>  */}

  </div>
       </div>
  );
}

export default SidebarStudentProfile;