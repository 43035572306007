

import React, { Children } from 'react';
import { Box, Button, Grid, Tab, Tabs, TextField, Typography } from '@mui/material';
import './tabView.css'
export interface TabViewProps {
    activeTab:string
    handleToggleBtn:(otp: string)=>void
}
export const TabView: React.FC<TabViewProps> = ({
    activeTab,
    handleToggleBtn
}) => {
  return (
<>
<Box sx={{ borderBottom: 1, borderColor: 'divider',marginBottom:2 }}>
  <Tabs value={activeTab} >
    <Tab onClick={()=>handleToggleBtn("Candidate")} classes={{root:'tabLabel'}} value={"Candidate"} label="Candidate"  />
    <Tab onClick={()=>handleToggleBtn("Recruiter")} classes={{root:'tabLabel'}} value={"Recruiter"} label="Recruiter"  />
  </Tabs>
</Box>

</>
  );
}

export default TabView;