
import React, { FC } from 'react'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


const LoadingBackdrop: FC<{ isVisible: boolean }> = ({ isVisible }) => {
   

    return (
        <Backdrop style={{ zIndex: 123,
            color: 'white',}} open={isVisible}>
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}

export default LoadingBackdrop
