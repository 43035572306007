import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import { Alert,  } from '@mui/material'

import React from 'react'








export interface GlobalSnackbarProps  {
    message: string
    open: boolean
    position?: 'top' | 'bottom'

}






/**
 * This is used to render the main content of the snackbar.  It checks if the message has '|'.
 * If it has a pipe, it renders an unordered list.
 * @param param0 message to display.
 */
const MessageContent = ({ message }: { message?: string }) => {
    const messages = message?.split('|') ?? ['']
    return (
        <div data-testid="clientSnackbar" style={{ display: 'flex' }}>
            {messages.length > 1 ? (
                <ul>
                    {messages.map((currentMessage, idx) => (
                        <li key={idx}>{currentMessage}</li>
                    ))}
                </ul>
            ) : (
                messages[0]
            )}
        </div>
    )
}


export const GlobalSnackbar: React.FC<GlobalSnackbarProps> = ({
    open,
    message,
    position,
}) => {

   

    return (
        <Snackbar anchorOrigin={{vertical:position??'top',horizontal:'center'}} open={open} autoHideDuration={5000} >
        <Alert
          severity="info"
          variant="filled"
          sx={{ width: '100%'}}
          
        >
         {message}
        </Alert>
      </Snackbar>
    )
}
