
import React, {FC, useRef } from 'react';

import { useNavigate } from 'react-router-dom';
import Sidebar from 'Common/Sidebar/Sidebar';
import SidebarRecruiterProfile from 'Common/SidebarRecruiterProfile/SidebarRecruiterProfile';
import { CareerOptions } from 'Common/Constants';
import ProfileGirl from 'Assets/profileGirl.svg'
import ProfileBoy from 'Assets/profileBoy.svg'
import Contractor from 'Assets/contractor.svg'
import CheckCareer from 'Assets/checkCareer.png'
import FileUpload from 'Assets/fileUpload.png'
import ProfilePic from 'Assets/profilePic.png'
import Next from 'Assets/arrowNext.png'
import Back from 'Assets/arrowBack.png'
import { Button, Chip, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Tooltip } from '@mui/material';
import { GlobalSnackbar } from 'Common/GlobalSnackbar';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Checkbox from '@mui/material/Checkbox';
import { Email } from '@mui/icons-material';
import LoadingBackdrop from 'Common/LoadingBackdrop';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';

const baseURL = 'https://nodebackend-fdctexhxfug3h4c5.eastus-01.azurewebsites.net/'
const RecruiterProfile: FC<{  }> = ({  }) => {
  const nav = useNavigate()
const [profileData,setProfileData] = React.useState<any>(
  

)
const vidRef = useRef(null);
const [user, setUser] = React.useState<
any
>()
const [loading, setLoading] = React.useState<
boolean
>(false)
const [newSkill,setNewSkill] = React.useState<string>('')
const [innerPageCount,setInnerPageCount] = React.useState<number>(1)
const [careerOptionData,setCareerOptionData] = React.useState<any>([{title:'Student',image:ProfileBoy,selected:false},
  {title:'Professional',image:ProfileGirl,selected:false},
  {title:'Contractor',image:Contractor,selected:false}])
  
const [currentSidebarPage,setCurrentSidebarPage] = React.useState<string>('RecruiterAccount')
const [snackbarMessage, setSnackbarMessage] = React.useState<
string
>("")
const [newIndustry,setNewIndustry] = React.useState<string>('')
const [newCategory,setNewCategory] = React.useState<string>('')

const handleClickArrow=React.useCallback((type:'Next'|'Previous'
)=>{
  handleSaveUserData()
  if(type==='Next'){
   
    if(currentSidebarPage==='Company'){
      if(innerPageCount===1){
        setInnerPageCount(2)
       }
       else if(innerPageCount===2){
        setInnerPageCount(3)
       }
       else if(innerPageCount===3){
        setInnerPageCount(1)
        setCurrentSidebarPage('Terms')
       }
    }
    if(currentSidebarPage==='RecruiterAccount'){
      
      
        setInnerPageCount(1)
        setCurrentSidebarPage('Company')
       
    }
    if(currentSidebarPage==='Terms'){
      nav('/portal')
    }
 

 

    }
    if(type==='Previous'){
     
      if(currentSidebarPage==='Company'){
        if(innerPageCount===1){
          setInnerPageCount(1)
          setCurrentSidebarPage('RecruiterAccount')
         }
         else if(innerPageCount===2){
          setInnerPageCount(1)
         
         }
         else if(innerPageCount===3){
          setInnerPageCount(2)
         
         }
      }
      if(currentSidebarPage==='RecruiterAccount'){
        
        
          setInnerPageCount(1)
         
      }
    
  
    if(currentSidebarPage==='Terms'){
      setInnerPageCount(3)
      setCurrentSidebarPage('Company')
    }
  
      }   
   
},[currentSidebarPage,innerPageCount])
const handleUploadFile = React.useCallback(async(file:any,type:string)=> {
  setLoading(true)
  const userData = window.localStorage.getItem('user')
const token = userData?JSON.parse(userData)?.token:''
const formData = new FormData();
formData.append(type, file)
return fetch(`https://nodebackend-fdctexhxfug3h4c5.eastus-01.azurewebsites.net/api/users/uploadFile`, {
method: 'POST',
headers:{'Access-Control-Allow-Origin':'*',Authorization:'Bearer ' + token},
body:formData

}) .then(response => {
return response.json();
}).then((data:any) => {
if(data?.file){
setSnackbarMessage(data?.message)
const localProfileData = {...profileData}
setProfileData({...localProfileData,companyLogo:data?.file})



}
setLoading(false)
}).catch((error)=>{
setSnackbarMessage(error.message)
setLoading(false)
})
},[profileData])
const handleUploadVideo = React.useCallback(async(file:any,type:string)=> {
  setLoading(true)
  const userData = window.localStorage.getItem('user')
const token = userData?JSON.parse(userData)?.token:''
const formData = new FormData();
formData.append(type, file)
return fetch(`https://nodebackend-fdctexhxfug3h4c5.eastus-01.azurewebsites.net/api/recruiters/uploadBrandVideo`, {
method: 'POST',
headers:{'Access-Control-Allow-Origin':'*',Authorization:'Bearer ' + token},
body:formData

}) .then(response => {
return response.json();
}).then((data:any) => {
if(data?.file){
setSnackbarMessage(data?.message)
const localProfileData = {...profileData}
setProfileData({...localProfileData,brandVideo:baseURL+data?.file})



}
setLoading(false)
}).catch((error)=>{
setSnackbarMessage(error.message)
setLoading(false)
})
},[profileData])
  const handleUploadPicker=(event:any,type:string)=>{
    const fileSize:number = Number(((event?.target?.files?.[0].size/1024)/1024).toFixed(4))
      if(type==='companyLogo'){
        if(fileSize > 5) {
          setSnackbarMessage('Only pdf. Max size 5 MB allowed')
         return
       }
       else{
        handleUploadFile(event?.target?.files?.[0],type)
       }
      }
      else if(type==='brandVideo'){
        if(fileSize > 20) {
          setSnackbarMessage('Only MP4 with Max size 20 MB allowed')
         return
       }
       else{
        handleUploadVideo(event?.target?.files?.[0],type)
       }
      }
    
    }
const handleSelectCareerOption=React.useCallback((selectedIndex:number)=>{
  setCareerOptionData(careerOptionData?.map((item:any,index:number)=>{
    return {...item,selected:index===selectedIndex?true:false}
  }))
  let localProfile = {...profileData}
  setProfileData({...localProfile,carrier_stack:{...localProfile?.carrier_stack,carrierType:careerOptionData?.[selectedIndex]?.title}})
},[careerOptionData,profileData])

const handleSaveUserData = React.useCallback(()=> {
  setLoading(true)
  const userData = window.localStorage.getItem('user')
  const token = userData?JSON.parse(userData)?.token:''
  console.log(profileData)
  return fetch(`https://nodebackend-fdctexhxfug3h4c5.eastus-01.azurewebsites.net/api/recruiters/updateRecruiterData`, {
    method: 'POST',
    headers:{'Access-Control-Allow-Origin':'*','Content-Type': 'application/json',Authorization:'Bearer ' + token},
    body:JSON.stringify(profileData)

  }) .then(response => {
    return response.json();
  }).then((data:any) => {
    setLoading(false)
    
    setSnackbarMessage(data.message)
   
  }).catch((error)=>{
    setLoading(false)

    setSnackbarMessage(error.message)
  })
},[profileData])
const getUserDetails = React.useCallback(async(userToken:string)=> {
  setLoading(true)

  return fetch(`https://nodebackend-fdctexhxfug3h4c5.eastus-01.azurewebsites.net/api/recruiters/getRecruiterData`, {
    method: 'GET',
    headers:{'Access-Control-Allow-Origin':'*','Content-Type': 'application/json',Authorization:'Bearer ' + userToken},

  }) .then(response => {
    return response.json();
  }).then((data:any) => {
    setLoading(false)
  if(data?.message?.includes('Cannot find User')){
    setSnackbarMessage('Cannot find User , please log in again')
    window.localStorage.clear()
    nav('/signin')

  }
  else{
    let localProfileObject = data?.data
    if(localProfileObject?.category===null){
      localProfileObject={...localProfileObject,category:[]}

    }
    if(localProfileObject?.industry===null){
      localProfileObject={...localProfileObject,industry:[]}

    }
    setProfileData({...localProfileObject,brandVideo:localProfileObject?.brandVideo?baseURL+localProfileObject?.brandVideo:'',companyLogo:localProfileObject?.companyLogo?baseURL+localProfileObject?.companyLogo:''})
  }
   console.log(data,'getRecruiterData')
  }).catch((error)=>{
    setLoading(false)

    setSnackbarMessage(error.message)
  })
},[profileData])
const handleChangeInput = React.useCallback((event:any,key:string,index?:number)=>{

  const localProfileData = {...profileData}
if(key==='CompanyName'){
  setProfileData({...localProfileData,companyName:event?.target?.value})
}
if(key==='Designation'){
  setProfileData({...localProfileData,designation:event?.target?.value})
}

    if(key==='category'){
      setNewCategory(event?.target?.value)
    }
    if(key==='industry'){
      setNewIndustry(event?.target?.value)
    }
       
    if(key==='Region'){
      setProfileData({...localProfileData,region:event?.target?.value})
    }
    if(key==='Country'){

      setProfileData({...localProfileData,country:event?.target?.value})
    }
    if(key==='Phone'){
      setProfileData({...localProfileData,phone:event?.target?.value})
    }
    if(key==='address'){
      setProfileData({...localProfileData,corperateAddress:event?.target?.value})
    }
       
    
},[profileData])

const renderCareer=()=>{
  return (<>
   <span className='relevantTextRecruiter'>What is revelant for you?</span>
    <div className='avatarContainer'>
   {careerOptionData?.map((item:any,index:number)=>{
     return (<div onClick={()=>handleSelectCareerOption(index)} className='avatarWrapper' style={{borderBottom:item?.selected?'2px solid #0250C5':''}}>
      <img className='profilePhoto' src={item?.image}  />
      <span className='profileText'>{item?.title}</span>
      {item?.selected && <img src={CheckCareer} className='checkCareer' />}
    </div>)
   }) }
    
    </div>
   
  
  </>)
}

const renderRecruiterAccount=()=>{
  return <>
<div className='educationWrapperRecruiter'>
      <span className='aboutYouText'>About Company</span>
      <span className='resumeLabel'>Name</span>
      <TextField onChange={(e)=>handleChangeInput(e,'Name')}  classes={{root:"inputFieldLinkedin"}} value={profileData?.name} type='text' />
      <span className='resumeLabel'>Work Email</span>
      <TextField  classes={{root:"inputFieldLinkedin"}}  value={user?.user?.email} type='text' />
      <span className='resumeLabel'>Designation</span>
      <TextField  classes={{root:"inputFieldLinkedin"}} onChange={(e)=>handleChangeInput(e,'Designation')} value={profileData?.designation} type='text' />

   
    
      </div>
  </>
   
}


const addType = React.useCallback((type:string,value:string)=>{
  if(value?.trim()?.length===0) return
  let localProfile = {...profileData}
  setProfileData({...localProfile,[type]:[...localProfile?.[type],value]})
setNewIndustry('')
setNewCategory('')
},[profileData])
const deleteType=React.useCallback((type:string,index:number)=>{
  const localProfileData = {...profileData}
  const newItems = [
    ...localProfileData?.[type].slice(0,index),
    ...localProfileData?.[type].slice(index+1)
  ]

  setProfileData({...localProfileData,[type]:newItems})


},[profileData])
const renderSkills=React.useCallback(()=>{
  return (<>
  
   
   <div className='skillsWrapper'>
   <span className='aboutYouText'>About Company</span>
   <span className='resumeLabel'>Industry</span>
   <TextField value={newIndustry} onChange={(e)=>handleChangeInput(e,'industry')} classes={{root:"inputFieldLinkedin"}} onBlur={(e)=>addType('industry',e?.target?.value)}  type='text' />
   <Stack direction="row" spacing={1} className='chipOuterRecruiter'>
   {profileData?.industry?.map((item:string,index:number)=>{
    return (      <Chip  classes={{root:'skillChip'}}  color="primary"  label={item} variant="outlined" onDelete={(e)=>deleteType('industry',index)} />
  )
   
    

   })}
     </Stack>
     <b/>
     <span className='resumeLabel'>Category</span>
   <TextField value={newCategory} onChange={(e)=>handleChangeInput(e,'category')} classes={{root:"inputFieldLinkedin"}} onBlur={(e)=>addType('category',e?.target?.value)}  type='text' />
   <Stack direction="row" spacing={1} className='chipOuterRecruiter'>
   {profileData?.category?.map((item:string,index:number)=>{
    return (      <Chip  classes={{root:'skillChip'}}  color="primary"  label={item} variant="outlined" onDelete={(e)=>deleteType('category',index)} />
  )
   
    

   })}
     </Stack>

  </div>
  
  </>)
},[profileData,newIndustry,newCategory])
const renderOthers=()=>{
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  return (<>
    <div className='otherWrapper'>
    <span className='otherHeaderTerms'>Terms & Condition</span>
    <textarea className='termsField' value={'Terms and condition document display'} />
    <div className='termsCheckbox'><Checkbox style={{padding:10}} {...label}  checked={true} /> I Agree to terms & condition</div>

  </div>
   
  
  </>)
}
const renderAddress=()=>{ 
  return (<div className='accountWrapper'>
    <span className='aboutYouText'>About Company</span>
    <span className='resumeLabel'>Company Name</span>
     <TextField onChange={(e)=>handleChangeInput(e,'CompanyName')} classes={{root:"inputFieldLinkedin"}}value={profileData?.companyName} type='text' />
     <span className='resumeLabel'>Corporate Address</span>
     <TextField onChange={(e)=>handleChangeInput(e,'address')} classes={{root:"inputFieldLinkedin"}} value={profileData?.corperateAddress} type='text' />
     <span className='resumeLabel'>Country</span>
     <TextField onChange={(e)=>handleChangeInput(e,'Country')} classes={{root:"inputFieldLinkedin"}} value={profileData?.country} type='text' />
     <span className='resumeLabel'>Region</span>
     <TextField onChange={(e)=>handleChangeInput(e,'Region')} classes={{root:"inputFieldLinkedin"}} value={profileData?.region} type='text' />
     <span className='resumeLabel'>Phone</span>
     <TextField onChange={(e)=>handleChangeInput(e,'Phone')} classes={{root:"inputFieldLinkedin"}} value={profileData?.phone} type='text' />
   
   </div>)
}
const deleteFile=React.useCallback((type:string)=>{
  const localProfileData = {...profileData}
  if(type==='companyLogo'){
    setProfileData({...localProfileData,companyLogo:''})

  }
  if(type==='brandVideo'){
    setProfileData({...localProfileData,brandVideo:''})

  }


},[profileData])

const renderResume=()=>{
  return (<div className='resumeWrapper'>
   <span className='relevantTextRecruiter'>Company Branding</span>
   <span className='resumeLabel'>Company Logo   </span>
   {profileData?.companyLogo && profileData?.companyLogo?.length!==0?<div className='selectProfileImageWrapper'>
      <img className='selectProfileImage' src={profileData?.companyLogo}/>
      <span 
     onClick={()=>deleteFile('companyLogo')}
       className='selectProfileText'>REMOVE COMPANY LOGO</span>
    </div> : <> <label htmlFor="filePicker"   >  
    <div className='inputFieldLinkedinUpload' >
  <img  className='iconFileUpload' src={FileUpload} /> drag file or click to browse
  
  </div> </label>
  <input id="filePicker" onChange={(e)=>handleUploadPicker(e,'companyLogo')} accept="image/png, image/jpeg"  style={{display:"none"}} type={"file"} />
  <span className='resumeBottomText'>Svg or Png files only. Max size 5MB  </span></> }

  <br/><br/>

  {profileData?.brandVideo && profileData?.brandVideo?.length!==0?<div className='selectProfileVideoWrapper'>
      <video  style={{marginRight:15}} ref={vidRef} id="myVideo" width="500" height="auto" controls>
  <source src={profileData?.brandVideo} type="video/mp4" />
  
</video>
<Tooltip  title="Remove Video"><DeleteIcon 
     onClick={()=>deleteFile('brandVideo')}
     htmlColor='#0250C5'
       className='deleteVideoIcon' fontSize='large' /></Tooltip>
      
    </div> : <> <label htmlFor="filePickerVideo"   >  
    <div className='inputFieldLinkedinUpload' >
  <img  className='iconFileUpload' src={FileUpload} /> drag file or click to browse
  
  </div> </label>
  <input id="filePickerVideo" onChange={(e)=>handleUploadPicker(e,'brandVideo')} accept="video/mp4,video/x-m4v,video/*"  style={{display:"none"}} type={"file"} />
  <span className='resumeBottomText'>Mp4 files only. Max size 20MB</span></> }


  </div>)
}
React.useEffect(()=>{
  if(snackbarMessage){
    const close = setTimeout(()=>{
      setSnackbarMessage('')
   clearTimeout(close)
    },5000)
  }
},[snackbarMessage])

React.useEffect(()=>{
    const userData = window.localStorage.getItem('user')
    const parsedUser =userData?JSON.parse(userData??''):null
  const token = userData?JSON.parse(userData)?.token:''


  setUser(parsedUser)
  if(token){
getUserDetails(token)
  }
  else {
    nav('/signin')
  }  
  
},[window])

  return (
    <div className="studentProfileWrapper">
      <SidebarRecruiterProfile    currentSidebarPage={currentSidebarPage} innerPageCount={innerPageCount}  /> 
      
      
     <div className='studentRecruiterContent'>
     
     <div className='saveButtonOuter'>
     <CloseIcon htmlColor='black' className='closeProfileIcon' onClick={()=>nav('/portal')} /></div>

     {currentSidebarPage==='RecruiterAccount' && renderRecruiterAccount()}
     {currentSidebarPage==='Company' && innerPageCount===1 && renderAddress()}
    {currentSidebarPage==='Company' &&  innerPageCount===2 && renderSkills()}
    {currentSidebarPage==='Company' &&  innerPageCount===3 && renderResume()}
     {currentSidebarPage==='Terms' && renderOthers()}

    </div>
    <div className='nextIconWrapper'>
      <img src={Back} style={{visibility:currentSidebarPage==='RecruiterAccount' && innerPageCount===1?'hidden':'visible'}}   className='welcomeArrow' onClick={()=>handleClickArrow('Previous')} />
      <img src={Next} className='welcomeArrow' onClick={()=>handleClickArrow('Next')} /> 

    </div>
    <GlobalSnackbar position='bottom' message={snackbarMessage}  open={snackbarMessage!==''} />
    <LoadingBackdrop isVisible={loading} />
    </div>
  );
}

export default RecruiterProfile;