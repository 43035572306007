
import React, { FC } from 'react'
import { Button, Dialog, Slide, TextField, Typography } from '@mui/material'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { useLocation, useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import './inviteUsers.css'
import LoadingBackdrop from 'Common/LoadingBackdrop';
import { GlobalSnackbar } from 'Common/GlobalSnackbar';
interface InviteUserProps {
    handleCloseDialog?:(value:boolean,callInvite?:boolean)=>void
    open?:boolean
}
const InviteUsersPopup: FC<InviteUserProps> = ({handleCloseDialog,open}) => {
   const nav = useNavigate()
const location = useLocation()
const [userData,setUserName]= React.useState<any>()
const [loading,setLoading]= React.useState<boolean>(false)
const [snackbarMessage, setSnackbarMessage] = React.useState<
string
>("")
const [inviteUserData,setInviteUserData]= React.useState<any>({name:'',email:'',designation:''})
const handleChangeInput=React.useCallback((event:any,type:string)=>{
    const localInviteUserData = {...inviteUserData}
    setInviteUserData({...localInviteUserData,[type]:event?.target?.value})
},[inviteUserData])
   React.useEffect(()=>{
    if(location?.state?.user ){
 setUserName(location?.state?.user)
    }
   },[location])
   const InviteUsers = React.useCallback(()=> {
    setLoading(true)
      const userData = window.localStorage.getItem('user')
    const token = userData?JSON.parse(userData)?.token:''
    return fetch(`https://nodebackend-fdctexhxfug3h4c5.eastus-01.azurewebsites.net/api/users/inviteUsers`, {
      method: 'POST',
      headers:{'Access-Control-Allow-Origin':'*','Content-Type': 'application/json',Authorization:'Bearer ' + token},
      body: JSON.stringify(inviteUserData)
    }) .then(response => {
      return response.json();
    }).then((data:any) => {
        setSnackbarMessage(data?.message)
        handleCloseDialog?.(false,true)
   
    }).catch((error)=>{
      console.log(error)
      setSnackbarMessage(error?.message)
      setLoading(false)
    })
  },[inviteUserData])

   const navigateUser = React.useCallback(()=>{
    if(userData?.user?.roleId===2){
        nav('/recruiterProfile')
    }
    else{
        nav('/userProfile')
    }
   },[userData])
    return (
        <Dialog
        open={open??false}
        fullWidth
        fullScreen
        classes={{container:'inviteDialog'}}
        >
    <Slide 
     direction="left"
                in={true}

                mountOnEnter
                unmountOnExit
                timeout={0}
                 ><div className='inviteUserContent'>
                    <div className='inviteHeadingContainer'><span className='inviteHeading'>Invite User</span>
                    <CloseIcon className='closeIconInvite' onClick={()=>handleCloseDialog?.(false)} htmlColor='black' />
                    </div>
                 <span className='resumeLabel'>Name</span>
                  <TextField onChange={(event)=>handleChangeInput(event,'name')} classes={{root:"inputFieldLinkedin"}} value={inviteUserData?.name} type='text' />
                  <span className='resumeLabel'>Email</span>
                  <TextField onChange={(event)=>handleChangeInput(event,'email')} classes={{root:"inputFieldLinkedin"}}  value={inviteUserData?.email} type='text' />
                  <span className='resumeLabel'>Designation</span>
                  <TextField onChange={(event)=>handleChangeInput(event,'designation')}  classes={{root:"inputFieldLinkedin"}}  value={inviteUserData?.designation} type='text' />
                  <Button onClick={()=>InviteUsers()} className='inviteButton'>Invite</Button>
                </div></Slide>
                <LoadingBackdrop isVisible={loading} />
                <GlobalSnackbar  message={snackbarMessage} open={snackbarMessage!==''} />

        </Dialog>
    )
}

export default InviteUsersPopup