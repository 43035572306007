
import React, { FC } from 'react'
import { Button, Checkbox, Dialog, MenuItem, Select, Slide, TextField, Typography } from '@mui/material'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { useLocation, useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import './job.css'
import LoadingBackdrop from 'Common/LoadingBackdrop';
import { GlobalSnackbar } from 'Common/GlobalSnackbar';
import clsx from 'clsx'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { ClassicEditor, Bold, Essentials, Italic, Mention,FontFamily, Paragraph, Undo, FontSize, FontColor, Heading, Link, List } from 'ckeditor5';
import { SlashCommand } from 'ckeditor5-premium-features';
import DeleteIcon from '@mui/icons-material/Delete';

import 'ckeditor5/ckeditor5.css';
import 'ckeditor5-premium-features/ckeditor5-premium-features.css';
import { FormatListBulleted, FormatListNumbered } from '@mui/icons-material';
import { ck } from 'Common/Constants';
interface CreateJobProps {
   
}
const CreateJob: FC<CreateJobProps> = ({}) => {
   const nav = useNavigate()
const location = useLocation()
const [userData,setUserName]= React.useState<any>()
const editorRef = React.useRef<any>();

const [loading,setLoading]= React.useState<boolean>(false)
const [snackbarMessage, setSnackbarMessage] = React.useState<
string
>("")
const [jobData,setJobData] = React.useState<any>({
  JobDetails: {
    company: "",
    jobTitle: "",
    jobLocation: "",
    jobType: "",
    experience: "",
    industry: "",
    jobFunction: ""
},
jobDescSkills: {
    jobDescription: "",
    skills: []
},
aboutCompany: {
    description: "",
    logoVideo: []
},
interviewDetails: {
    Joblocation: [{location:'',position:1}],
    position: 5,
    numberInterview: [
        {
            interviewerName: "",
            description: ""
        }
    ]
},
others: [
    {
        postAsJobInfo: false,
        parameterName: "",
        parameterValue:""
    }
]
})


   React.useEffect(()=>{
    if(location?.state?.user ){
 setUserName(location?.state?.user)
    }
   },[location])
const handleChangeInput=React.useCallback((event:any,type:string,key:string,index?:number)=>{
  const value = event?.target?.value
  const localJobData = {...jobData}
//   JobDetails: {
//     company: "",
//     jobTitle: "",
//     jobLocation: "",
//     jobType: "",
//     experience: "",
//     industry: "",
//     jobFunction: ""
// },
if(type==='JobDetails'){
  setJobData({...localJobData,JobDetails:{...localJobData?.JobDetails,[key]:value}})
}
     
},[jobData])

// const deleteSkill=React.useCallback((index:number)=>{
// const localProfileData = {...profileData}
// const newSkills = [
//   ...localProfileData?.skills?.name.slice(0,index),
//   ...localProfileData?.skills?.name.slice(index+1)
// ]

// setProfileData({...localProfileData,skills:{...localProfileData?.skills,name:newSkills}})


// },[profileData])
const addMoreContent = React.useCallback((type?:string)=>{
  if(type==='Joblocation'){
  const JobLocationObject = {location:'',position:0}
  setJobData({...jobData,interviewDetails:{...jobData.interviewDetails,Joblocation:[...jobData?.interviewDetails?.Joblocation,JobLocationObject]}})
  }
  else if(type==='numberInterview') {
    const numberInterviewObject = {
      interviewerName: "",
      description: ""
  }
    setJobData({...jobData,interviewDetails:{...jobData.interviewDetails,numberInterview:[...jobData?.interviewDetails?.numberInterview,numberInterviewObject]}})
  }
  else{
 const othersObject = {
  postAsJobInfo: false,
  parameterName: "",
  parameterValue:""
}
setJobData({...jobData,others:[...jobData?.others,othersObject]})

  }
},[jobData])
const deleteContent = React.useCallback((index:number,type?:string)=>{

  if(type==='Joblocation' || type==='numberInterview'){
    const localJobData = {...jobData}

const newJobData = [
  ...localJobData?.interviewDetails?.[type].slice(0,index),
  ...localJobData?.interviewDetails?.[type].slice(index+1)
]
setJobData({...localJobData,interviewDetails:{...localJobData.interviewDetails,[type]:newJobData}})

    }
  
    else{
      const localJobData = {...jobData}

      const newJobData = [
        ...localJobData?.others?.slice(0,index),
        ...localJobData?.interviewDetails?.slice(index+1)
      ]
      setJobData({...jobData,others:newJobData})
      
  
    }
},[jobData])
const createJob = React.useCallback(()=>{

},[])
const renderBasicDetails=React.useMemo(()=>{
return (<div className='jobMainWrapper'>
    <div className='educationIndex'>1</div>
     <div className='jobPartContainer'>
            <span className='jobPartContainerLabel'>Basic Job Details</span>
          <div className='inputRow'>
          <div className='jobInputContainer'>
            <span className='jobCompanyInputLabel'>Company <sup>*</sup></span>
            <TextField  classes={{root:"jobCompanyInput"}} value={jobData?.JobDetails?.company} onChange={(e)=>handleChangeInput(e,'JobDetails','company')}  type='text' />
            </div>
            <div className='jobInputContainer'>
            <span className={clsx('jobCompanyInputLabel')}>Job Title<sup>*</sup></span>
            <TextField  classes={{root:clsx("jobCompanyInputTitle",'jobCompanyInput')}} 
            value={jobData?.JobDetails?.jobTitle}
            onChange={(e)=>handleChangeInput(e,'JobDetails','jobTitle')}  type='text' />
            </div>
          </div>
    
          <div className='inputRow'>
          <div className='jobInputContainer'>
            <span className='jobCompanyInputLabel'>Job Location <sup>*</sup></span>
            <TextField  classes={{root:"jobCompanyInput"}}
                        value={jobData?.JobDetails?.jobLocation}

            onChange={(e)=>handleChangeInput(e,'JobDetails','jobLocation')}  type='text' />
            </div>
            <div className='jobInputContainer'>
            <span className={clsx('jobCompanyInputLabel')}>Job Type<sup>*</sup></span>
            <TextField  classes={{root:clsx('jobCompanyInput')}} 
                                    value={jobData?.JobDetails?.jobType}

            onChange={(e)=>handleChangeInput(e,'JobDetails','jobType')}  type='text' />
            </div>
            <div className='jobInputContainer'>
            <span className={clsx('jobCompanyInputLabel')}>Experience<sup>*</sup></span>
            <TextField  classes={{root:clsx('jobCompanyInput')}} 
            value={jobData?.JobDetails?.experience}
            onChange={(e)=>handleChangeInput(e,'JobDetails','experience')}  type='text' />
            </div>
          </div>
    
          <div className='inputRow'>
          <div className='jobInputContainer'>
            <span className='jobCompanyInputLabel'>Industry<sup>*</sup></span>
            <Select
        className='jobCompanyInput'
        value={'Cloud Computing'}
        onChange={(e)=>console.log(e?.target?.value)}
      >
        <MenuItem value={'Cloud Computing'}>Cloud Computing</MenuItem>
        <MenuItem value={'Cloud Computing'}>Cloud Computing</MenuItem>
      </Select>
            </div>
            <div className='jobInputContainer'>
            <span className={clsx('jobCompanyInputLabel')}>Job Function<sup>*</sup></span>
            <Select
        className='jobCompanyInput'
        value={'Data Engineering'}
        onChange={(e)=>console.log(e?.target?.value)}
      >
        <MenuItem value={'Data Engineering'}>Data Engineering    </MenuItem>
        <MenuItem value={'Cloud Computing'}>Cloud Computing</MenuItem>
      </Select>
    
            </div>
            <div className='jobInputContainer'>
            <span className={clsx('jobCompanyInputLabel')}>Estimated Salary<sup>*</sup></span>
            <Select
        className='jobCompanyInput'
        value={'$150K - $200K'}
        onChange={(e)=>console.log(e?.target?.value)}
      >
        <MenuItem value={'$50K - $100K'}>$50K - $100K</MenuItem>
        <MenuItem value={'$150K - $200K'}>$150K - $200K</MenuItem>
      </Select>        </div>
          </div>
        </div>
    </div>)

},[jobData])
console.log(jobData)
const createJobAPI = React.useCallback(()=> {
    const userData = window.localStorage.getItem('user')
const token = userData?JSON.parse(userData)?.token:''
  return fetch(`https://nodebackend-fdctexhxfug3h4c5.eastus-01.azurewebsites.net/api/recruiters/createJob`, {
    method: 'POST',
    headers:{'Access-Control-Allow-Origin':'*','Content-Type': 'application/json',Authorization:'Bearer ' + token},
    body: JSON.stringify(jobData)
  }) .then(response => {
    return response.json();
  }).then((data:any) => {
  console.log(data)
  
  }).catch((error)=>{
    console.log(error)
    setSnackbarMessage('Login Error')
    setLoading(false)
  })
},[jobData])
const renderDescription=React.useMemo(()=>{
    return (<div className='jobMainWrapper'>
        <div className='educationIndex'>2</div>
         <div className='jobPartContainer'>
                <span className='jobPartContainerLabel'>Job Description & Skills</span>
                <div className='inputRow'>
          <div className='jobInputContainer'>
            <span className='jobCompanyInputLabel'>Job Description <sup>*</sup></span>
            <CKEditor
            editor={ ClassicEditor }
            config={ {
                toolbar: {
        icon: 'text',
                    items: ['heading',
            'bold',
            'italic',
            'essentials',
            'fontSize',
            'fontFamily',
            'fontColor',
            'link',
            'list',
        
             ],
                },
                heading: {
                    options: [
                        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                        { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                        { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' }
                    ]
                },
                plugins: [
                   Heading, Bold,Italic, Essentials,FontSize,FontFamily,FontColor,Link, List
                ],
                licenseKey: '<YOUR_LICENSE_KEY>',
              
                initialData: ck,
               
            } }
            onReady={ (editor) => {
                
                editorRef.current = editor

                
            } }
           onChange={(data:any)=>console.log(editorRef?.current?.getData())} 
        />
            </div>
           
          </div>
          <div className='jobInputContainer'>
            <span className={clsx('jobCompanyInputLabel')}>Skills<sup>*</sup></span>
            <TextField  classes={{root:clsx("jobCompanyInputTitle",'jobCompanyInput')}} onChange={(e)=>console.log(e,'Title')}  type='text' />
            </div>
            </div>
           
        </div>)
},[jobData])
const renderAboutCompany=React.useMemo(()=>{
  return (<div className='jobMainWrapper'>
      <div className='educationIndex'>3</div>
       <div className='jobPartContainer'>
              <span className='jobPartContainerLabel'>About Company</span>
              <div className='inputRow'>
        <div className='jobInputContainer'>
          <span className='jobCompanyInputLabel'>Description<sup>*</sup></span>
          <CKEditor
          editor={ ClassicEditor }
          config={ {
              toolbar: {
      icon: 'text',
                  items: ['heading',
          'bold',
          'italic',
          'essentials',
          'fontSize',
          'fontFamily',
          'fontColor',
          'link',
          'list',
      
           ],
              },
              heading: {
                  options: [
                      { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                      { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                      { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' }
                  ]
              },
              plugins: [
                 Heading, Bold,Italic, Essentials,FontSize,FontColor,Link, List
              ],
              licenseKey: '<YOUR_LICENSE_KEY>',
            
              initialData: `At Amazon Web Services (AWS), we’re hiring highly technical cloud computing architects to help our partners
develop technical expertise and capacity, while also collaborating with our customers and partners on key
engagements. Our consultants will deliver proof-of-concept projects, topical workshops, and lead
implementation projects. These professional services engagements will focus on key customer solutions such
as, web applications, enterprise applications, HPC, batch processing and big data, archiving and disaster
recovery, education and government.
Professional Services engage in a wide variety of projects for customers and partners, providing collective
experience from across the AWS customer base and are obsessed about strong success for the Customer. Our
team collaborates across the entire AWS organization to bring access to product and service teams, to get the
right solution delivered and drive feature innovation based upon customer needs.`,
             
          } }
          onReady={ (editor) => {
              
              editorRef.current = editor

              
          } }
         onChange={(data:any)=>console.log(editorRef?.current?.getData())} 
      />
          </div>
         
        </div>
          </div>
      </div>)
},[jobData])
const renderInterViewDetails=React.useMemo(()=>{
  return (<div className='jobMainWrapper'>
      <div className='educationIndex'>4</div>
       <div className='jobPartContainer'>
              <span className='jobPartContainerLabel'>Interview Details</span>
              <div className='inputRow'>
        <div className='jobInputContainer'>
          <span className='jobCompanyInputLabel'>Description<sup>*</sup></span>
         
          {jobData?.interviewDetails?.Joblocation?.map((item:any,index:number)=>{
            return ( <div className='inputRow'>
              <div className='jobInputContainer'>
                <span className='jobCompanyInputLabel'>Job Opening Location <sup>*</sup></span>
                <TextField  classes={{root:"jobCompanyInput"}} value={item?.location} onChange={(e)=>console.log(e,'Company')}  type='text' />
                </div>
                <div className='jobInputContainer'>
                <span className={clsx('jobCompanyInputLabel')}>Number of positions to fill <sup>*</sup></span>
                <TextField value={item?.position} type="number"  classes={{root:clsx("jobCompanyInputTitle",'jobCompanyInput')}} onChange={(e)=>console.log(e,'Title')}   />
                </div>
                {jobData?.interviewDetails?.Joblocation?.length>1 &&<DeleteIcon  onClick={()=>deleteContent(index,'Joblocation')} fontSize='medium'  htmlColor='grey' className='deleteIconJob'  />}

              </div>)
            
          })}
          <span onClick={()=>addMoreContent('Joblocation')} className='addMoreLocations'>+ Add More locations</span>
          <span className='noOFInterviews'>Number of interviews for this job post </span>
          <div className='jobInputContainer'>
            <span className={clsx('jobCompanyInputLabel')}>Interview Name<sup>*</sup></span>
            <TextField disabled  classes={{root:clsx("jobCompanyInputInputName",'jobCompanyInput')}} value={'Self Interview'}  type='text' />
            </div>
            <div className='jobInputContainer'>
            <span className={clsx('jobCompanyInputLabel')}>Description<sup>*</sup></span>
            <textarea  rows={4}  className={clsx("jobCompanyInputInputDescription",'jobCompanyInput')}  />
            </div>
            
            <div className='jobInputContainer'>
            <div className='jobInputContainer'>
            <span className={clsx('jobCompanyInputLabel')}>Interview Name<sup>*</sup></span>
            <TextField disabled  classes={{root:clsx("jobCompanyInputInputName",'jobCompanyInput')}} value={'Manager Interview'}  type='text' />
            </div>
            <span className={clsx('jobCompanyInputLabel')}>Description<sup>*</sup></span>
            <textarea  rows={4}  className={clsx("jobCompanyInputInputDescription",'jobCompanyInput')}  />
            </div>
            {jobData?.interviewDetails?.numberInterview?.map((item:any,index:number)=>{
              return (
              <div className='jobInterViewWrapper'>
             <div>
             <div className='jobInputContainer'>
            <span className={clsx('jobCompanyInputLabel')}>Interview Name<sup>*</sup></span>
            <TextField disabled  classes={{root:clsx("jobCompanyInputInputName",'jobCompanyInput')}} value={item?.interviewerName}  type='text' />
            </div>
            <div className='jobInputContainer'>
                <span className={clsx('jobCompanyInputLabel')}>Description<sup>*</sup></span>
                <textarea value={item?.description}  rows={4}  className={clsx("jobCompanyInputInputDescription",'jobCompanyInput')}  />
                </div>
             </div>
                {jobData?.interviewDetails?.numberInterview?.length>1 &&<DeleteIcon  onClick={()=>deleteContent(index,'numberInterview')} fontSize='medium'  htmlColor='grey' className='deleteIconInterview'  />}

              </div>
              ) 
            })}
            <span onClick={()=>addMoreContent('numberInterview')} className='addMoreLocations'>+ Add More Interview Rounds</span>
          </div>
       
        </div>
          </div>
      </div>)
},[jobData])
const renderOthers=React.useMemo(()=>{
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  return (<div className='jobMainWrapper'>
      <div className='educationIndex'>1</div>
       <div className='jobPartContainer'>
              <span className='jobPartContainerLabel'>Other (Optional)</span> <br/>
              <span className="otherText">Use this section to add extra details for the job posting. You can make the below-added information to display as “Other Details”
              along with the rest of the job info. </span>
              <br/>
              <div className='jobInfoCheckWrapper'><Checkbox className='jobInfoCheckbox'  {...label}   />Post as Job Info              </div> 
           {jobData?.others?.map((item:any,index:number)=>{
            return ( <div className='inputRow'>
              <div className='jobInputContainer'>
                <span className='jobCompanyInputLabel'>Parameter Name                
                </span>
                <TextField value={item?.parameterName} classes={{root:"jobCompanyInput"}} onChange={(e)=>console.log(e,'Company')}  type='text' />
                </div>
                <div className='jobInputContainer'>
                <span className={clsx('jobCompanyInputLabel')}>Parameter Value</span>
                <TextField value={item?.parameterValue}  classes={{root:clsx("jobCompanyInputTitle",'jobCompanyInput')}} onChange={(e)=>console.log(e,'Title')}  type='text' />
                </div>
                {jobData?.others?.length>1 &&<DeleteIcon  onClick={()=>deleteContent(index,'others')} fontSize='medium'  htmlColor='grey' className='deleteIconOther'  />}

              </div>)
           })}
            <br/>
            <span onClick={()=>addMoreContent()}  className='addMoreLocations'>+ ADD MORE DETAILS</span>
   
          </div>

      </div>)
  
  },[jobData])
    return (
        <>
        
{renderBasicDetails}
{renderDescription}
{renderAboutCompany}
{renderInterViewDetails}
{renderOthers}
<div className='postJob'>          <Button className={clsx('signInButton','postButton')} onClick={()=>createJobAPI()}>POST</Button>   
</div>
<LoadingBackdrop isVisible={loading} />
<GlobalSnackbar position='bottom' message={snackbarMessage}  open={snackbarMessage!==''} />

</>
    )
}

export default CreateJob