import './SearchBar.css';
import React, { Children } from 'react';
import { Button, Grid, TextField, Typography } from '@mui/material';
import SearchIcon from '../../Assets/search.png'
import FilterIcon from '../../Assets/filter.png'

function SearchBar() {
  return (
  <div className='SearchBar'>

    <div>
    <img className="seachIcon" src={SearchIcon} alt="icon" />
    <input type="text" className='seachInput' placeholder="Search for a posting" name="fname"></input>
    <div className='rightSide'>
        <img className="seachIconTwo" src={SearchIcon} alt="icon" />
        <img className="filterIcon" src={FilterIcon} alt="icon" />
    </div>
    
    </div>

  </div>
  );
}

export default SearchBar;