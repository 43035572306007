import './sidebar.css';
import React, { Children } from 'react';
import { Button, Grid, TextField, Typography } from '@mui/material';
import Logo from '../../Assets/Logo.svg'
function Sidebar() {
  return (
    <Grid className="sidebarWrapper">
         <a href=""><img className='logo' src={Logo} alt="logo" /></a>
  <Grid className='sidebarContent'>
  <Typography className='innerText'>Significant reduction in hiring cost
   </Typography>
   <div className='line'></div>
   <Typography className='innerText'>Recruitment cycle time reduction
   </Typography>
   <div className='line'></div>
   <Typography className='innerText'>The right candidate for the right job</Typography>
   <div className='line'></div>
   <Typography className='innerText'>Employee retention
   </Typography>
   <div className='line'></div>
   <Typography className='innerText'>Global outreach

   </Typography>
   <div className='line'></div>
  </Grid>
       </Grid>
  );
}

export default Sidebar;