import './contactus.css';
import React, { Children } from 'react';
import { Alert, Button, Grid, Snackbar, TextField, Typography } from '@mui/material';
import MicrosoftLogin from 'react-microsoft-login';
import { AuthError } from '@azure/msal-common';
import { redirect,useLocation,useNavigate } from 'react-router-dom';
import Sidebar from 'Common/Sidebar/Sidebar';
import Windows from '../../Assets/windows.png'
import TabView from 'Common/TabView/TabView';
import { validateEmail } from 'Utils/utils';
import { Global } from '@emotion/react';
import { GlobalSnackbar } from 'Common/GlobalSnackbar';
import LoadingBackdrop from 'Common/LoadingBackdrop';
import OTPVerify from 'Views/OTPVerify/OTPVerify';
import { Email } from '@mui/icons-material';
function ContactUs() {
  const nav = useNavigate()
  const location = useLocation();

  const [activeTab,setActiveTab] = React.useState<string>('Candidate')
  const [studentName, setStudentName] = React.useState<
  string
>('')
const [token, setToken] = React.useState<
string
>('')
const [user, setUser] = React.useState<
any
>()
const [otp, changeOTP] = React.useState<
string
>('')
const [loading, setLoading] = React.useState<
boolean
>(false)
const [showOtpScreen, setShowOtpScreen] = React.useState<
boolean
>(false)
const [studentNameError, setStudentNameError] = React.useState<
boolean
>(false)
const [snackbarMessage, setSnackbarMessage] = React.useState<
string
>("")
const [studentEmail, setStudentEmail] = React.useState<
string
>('')
const [studentPhoneError, setStudentPhoneError] = React.useState<
boolean
>(false)
const [studentOrgError, setStudentOrgError] = React.useState<
boolean
>(false)
const [studentMsgError, setStudentMsgError] = React.useState<
boolean
>(false)
const [studentPhone, setStudentPhone] = React.useState<
string
>('')
const [studentOrg, setStudentOrg] = React.useState<
string
>('')
const [studentMsg, setStudentMsg] = React.useState<
string
>('')
const [studentEmailError, setStudentEmailError] = React.useState<
boolean
>(false)
const [studentPassword, setStudentPassword] = React.useState<
string
>('')
const [studentPasswordError, setStudentPasswordError] = React.useState<
boolean
>(false)
 const loginRedirect=React.useCallback((error: AuthError | null, result?: any)=>{
console.log(error,result)
localStorage.setItem('user',JSON.stringify(result))
nav('/dashboard')
 },[])
 const handleChangeStudentInput = React.useCallback((type:string,value:string)=>{
  
  if(type==='Name'){
   if(studentNameError){
     setStudentNameError(false)
   }
   setStudentName(value)
  }
  else if(type==='Email'){
setStudentEmail(value)
if(studentEmailError){
 setStudentEmailError(false)
}
  }  else if(type==='Phone'){
    setStudentPhone(value)
    if(studentPhoneError){
     setStudentPhoneError(false)
    }
      }  else if(type==='Org'){
        setStudentOrg(value)
        if(studentOrgError){
         setStudentOrgError(false)
        }
          }  else if(type==='Msg'){
            setStudentMsg(value)
            if(studentMsgError){
             setStudentMsgError(false)
            }
              }




  else{
   if(studentPasswordError){
     setStudentPasswordError(false)
   }
setStudentPassword(value)
  }
 },[studentNameError,studentPasswordError,studentEmailError,studentPhoneError,studentOrgError,studentMsgError])
 const StudentSignUp = React.useCallback(()=> {
  return fetch(`https://nodebackend-fdctexhxfug3h4c5.eastus-01.azurewebsites.net/api/users`, {
    method: 'POST',
    headers:{'Access-Control-Allow-Origin':'*','Content-Type': 'application/json'},
    body: JSON.stringify({name:studentName,email:studentEmail,password:studentPassword,role:activeTab==='Candidate'?1:2})
  }) .then(response => {
    return response.json();
  }).then((data:any) => {
    if(data.message){
      setSnackbarMessage(data.message)
    }
    else{
      setToken(data)
     setUser(data?.newUser)
      handleSendOtp(data.token,data?.newUser?.email)
    }
setLoading(false)
  }).catch((error)=>{
    console.log(error,'error')
    setSnackbarMessage(error.message)
    setLoading(false)
  })
},[studentName,studentEmail,studentPassword,activeTab])
const handleClickVerification = React.useCallback(()=> {
  setLoading(true)
  return fetch(`https://nodebackend-fdctexhxfug3h4c5.eastus-01.azurewebsites.net/api/users/verifyOtp`, {
    method: 'POST',
    headers:{'Access-Control-Allow-Origin':'*','Content-Type': 'application/json',Authorization:'Bearer ' + token},
    body: JSON.stringify({otp:Number(otp),user:user})
  }) .then(response => {
    return response.json();
  }).then((data:any) => {
    if(data.message){
      localStorage.removeItem('user')
      localStorage.setItem('user',JSON.stringify(data))
      setShowOtpScreen(false)
      setLoading(false)
      nav('/welcome',{ state: { user: data } })

    }
    
setLoading(false)
   
  }).catch((error)=>{
    console.log(error,'error')
    setSnackbarMessage(error.message)
    setLoading(false)
  })
},[otp,user])
const handleSendOtp = React.useCallback(async(userToken?:string,email?:string)=> {
  return fetch(`https://nodebackend-fdctexhxfug3h4c5.eastus-01.azurewebsites.net/api/users/sendOtp`, {
    method: 'POST',
    headers:{'Access-Control-Allow-Origin':'*','Content-Type': 'application/json',Authorization:'Bearer ' + userToken??token},
    body: JSON.stringify({token:token,email:email})

  }) .then(response => {
    return response.json();
  }).then((data:any) => {
    if(data.message==='OTP sent successfully'){
      console.log(data,'sendOtp')
      setUser(data?.user)
      setSnackbarMessage(data.message)      
      setShowOtpScreen(true)
      setLoading(false)
    }
    else{
      setSnackbarMessage(data.message)
      setLoading(false)
    }
   
   
  }).catch((error)=>{
    console.log(error,'error')
    setSnackbarMessage(error.message)
    setLoading(false)
  })
},[token])
 const handleClickSignUpButton = React.useCallback(()=>{
  let inputsValidated = true
    if(studentName?.trim()?.length===0){
     setStudentNameError(true)
     inputsValidated=false
    }
   
    if(studentEmail?.trim()?.length===0 || !validateEmail(studentEmail)){
setStudentEmailError(true)
inputsValidated=false
    }
    if(studentPhone?.trim()?.length===0){
     setStudentPhoneError(true)
     inputsValidated=false
    } 
    if(studentOrg?.trim()?.length===0){
      setStudentOrgError(true)
      inputsValidated=false
     } 
     if(studentMsg?.trim()?.length===0){
      setStudentMsgError(true)
      inputsValidated=false
     } 
    if(inputsValidated){
      setLoading(true)
      StudentSignUp()
    }

 },[studentName,studentEmail,studentPassword,studentPhone,studentOrg,studentMsg])
 const setOtp=React.useCallback((otp:string)=>{
  console.log(otp)
  changeOTP(otp)

 },[])
 const handleToggleBtn=React.useCallback((value:string)=>{

  setActiveTab(value);
  
  },[])

 React.useEffect(()=>{
  if(snackbarMessage!==''){
    const messageTimeout = setTimeout(()=>{
      setSnackbarMessage('')
      clearTimeout(messageTimeout)
    },5000)
  }
 },[snackbarMessage])
 React.useEffect(()=>{
  if(location?.state?.email && location?.state?.email?.trim()?.length!==0){
    handleSendOtp(undefined,location?.state?.email)
    setUser({email:location?.state?.email})
  }
 },[location])

  return (
    <div className="loginWrapper">
      <div className='sideWrapper'><Sidebar /> </div>
      
     <div className='inputWrapper'>
     <Typography variant='h4' classes={{root:"getStartedText"}}>Contact Us</Typography>
    
     <TextField required error={studentNameError} value={studentName} onChange={(e)=>handleChangeStudentInput('Name',e.target.value)} classes={{root:"inputField"}} label="Name" type='text'/>
      <TextField required error={studentEmailError} value={studentEmail} onChange={(e)=>handleChangeStudentInput('Email',e.target.value)} classes={{root:"inputField"}}   label="Email" type='text' />


      <TextField required error={studentPhoneError} value={studentPhone} onChange={(e)=>handleChangeStudentInput('Name',e.target.value)} classes={{root:"inputField"}} label="Phone Number" type='text'/>
      <TextField required error={studentOrgError} value={studentOrg} onChange={(e)=>handleChangeStudentInput('Name',e.target.value)} classes={{root:"inputField"}} label="Organization" type='text'/>
      <TextField required error={studentMsgError} value={studentMsg} onChange={(e)=>handleChangeStudentInput('Name',e.target.value)} classes={{root:"inputField"}} label="Message" type='text'/>
        
     <div className='buttonWrapper'>
          <Button className='signUpButton' onClick={handleClickSignUpButton}>Continue</Button>    
      </div>

  </div>
<div className='signInDiv'><div className='signInText'>Have an account? </div>  <Button onClick={()=>nav('/signin')} className='signUpButton'>Sign In</Button> </div>
<GlobalSnackbar  message={snackbarMessage} open={snackbarMessage!==''} />
<LoadingBackdrop isVisible={loading} />
    </div>
  );
}

export default ContactUs;