import './otpverify.css';
import React, { Children } from 'react';
import { Button, Grid, TextField, Typography } from '@mui/material';
import MicrosoftLogin from 'react-microsoft-login';
import { AuthError } from '@azure/msal-common';
import { redirect,useNavigate } from 'react-router-dom';
import OtpInput from 'react-otp-input';

interface OTPVerifyProps {
    otp:string
    setOtp:(otp: string)=>void
}
const OTPVerify: React.FC<OTPVerifyProps> = ({
   otp,
   setOtp
}) => {

  return (
    <div className="verification">
      <Typography className='verificationText'>Verification Code - sent on your email address</Typography>
      <OtpInput
      value={otp}
      onChange={setOtp}
      containerStyle={{marginBottom:20}}
      inputStyle={{boxSizing: 'border-box',
        height: 47,
        width: 62,
        border: '1px solid #D8DBE1',
        borderRadius: 4,marginRight:9}}
      numInputs={6}
      renderSeparator={<span>  </span>}
      renderInput={(props) => <input    {...props} />}
    />
     
    </div>
  );
}

export default OTPVerify;